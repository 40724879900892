<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_add_affiliazione_tab"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <SelectComitatoSecondario
        v-if="affiliazioneSecondaria && !affiliazioneSecondariaCheckOk"
        :affiliazioneSecondaria="affiliazioneSecondaria"
        :societa="societa"
        @checkComitatoAffSecondaria="checkComitatoAffSecondaria"
      />
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        v-if="
          (affiliazioneSecondaria && affiliazioneSecondariaCheckOk) ||
          !affiliazioneSecondaria
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="affiliazioneSecondaria && societa">
              Nuova affiliazione secondaria
              {{ societa.denominazione + " - " + comitatoSelected }}
            </h5>
            <h5 class="modal-title" v-else-if="affiliazioneSecondaria">
              Nuova affiliazione secondaria - {{ comitatoSelected }}
            </h5>
            <h5 class="modal-title" v-else>Nuova affiliazione</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetComitato()"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <nav
            class="navbar navbar-expand-lg navbar-light bg-light px-5"
            v-if="visualizzaTab"
          >
            <div>
              <button
                class="navbar-brand"
                :class="active == 'affiliazione' ? 'active' : ''"
                @click="
                  active = 'affiliazione';
                  checkAff(
                    !affiliazioneSecondaria ? societa.id : societaSecondaria
                  );
                "
              >
                Affiliazione
              </button>
              <button
                v-if="!affiliazioneSecondaria"
                class="navbar-brand"
                :class="active == 'atto' ? 'active' : ''"
                @click="active = 'atto'"
              >
                Atto
              </button>
              <button
                v-if="!affiliazioneSecondaria"
                class="navbar-brand"
                :class="active == 'consiglio' ? 'active' : ''"
                @click="active = 'consiglio'"
              >
                Consiglio
              </button>
              <button
                class="navbar-brand"
                :class="active == 'impianti' ? 'active' : ''"
                @click="active = 'impianti'"
              >
                Impianti
              </button>
              <button
                v-if="!affiliazioneSecondaria"
                class="navbar-brand"
                :class="active == 'pickleball' ? 'active' : ''"
                @click="active = 'pickleball'"
              >
                Pickleball
              </button>
              <button
                v-if="!affiliazioneSecondaria"
                class="navbar-brand"
                :class="active == 'tutela-minori' ? 'active' : ''"
                @click="active = 'tutela-minori'"
              >
                Tutela minori
              </button>
              <button
                class="navbar-brand"
                :class="active == 'documenti' ? 'active' : ''"
                @click="active = 'documenti'"
              >
                Documenti
              </button>
            </div>
          </nav>
          <!--INIZIO BODY TAB-->
          <div
            class="modal-body text-center"
            v-if="active == 'affiliazione' && isLoadingAffiliazione"
          >
            <div class="pb-5 pt-4">
              <div
                class="spinner-border text-gray-600 mt-5"
                role="status"
              ></div>
              <span class="text-gray-600 ps-3 fs-2 mt-5">Loading</span>
            </div>
          </div>
          <div
            class="modal-body"
            v-if="active == 'affiliazione' && !isLoadingAffiliazione"
          >
            <div
              class="card-body p-6"
              v-if="
                (messaggioCheckAffiliazione && !affiliazioneSecondaria) ||
                (!affiliazioneNoCampi &&
                  affiliazioneSecondaria &&
                  messaggioCheckAffiliazione)
              "
            >
              <div class="bg-light-danger py-7 px-4 rounded">
                <div
                  class="text-danger fw-bold fs-5 text-center"
                  v-html="messaggioCheckAffiliazione"
                ></div>
              </div>
            </div>
            <div
              class="bg-light-danger text-danger p-6 rounded text-center m-5"
              v-if="affiliazioneNoCampi && affiliazioneSecondaria"
            >
              <b>
                ATTENZIONE!<br />
                <br />Non è possibile effettuare un'affiliazione secondaria di
                tipo B. <br />Per procedere selezionare uno o più impianti
              </b>
            </div>
            <div
              class="bg-light-danger text-danger p-6 rounded text-start m-5"
              v-else-if="!messaggioCheckAffiliazione && affiliazioneNoCampi"
            >
              <b>ATTENZIONE!</b> Si sta effettuando un'affiliazione senza campi!
            </div>
            <div
              class="card-body p-6"
              v-if="
                (!messaggioCheckAffiliazione && !affiliazioneSecondaria) ||
                (!affiliazioneNoCampi &&
                  affiliazioneSecondaria &&
                  !messaggioCheckAffiliazione)
              "
            >
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-5">
                    <label class="fw-bold">Tipo affiliazione</label>
                    <input
                      class="form-control"
                      v-model="tipologia_affiliazione_string"
                      readonly
                    />
                    <Field
                      name="tipoaff"
                      type="hidden"
                      class="form-control"
                      v-model="codice_tipo_affiliazione"
                    />
                    <!-- <SelectInput
                      name="tipoaff"
                      :options="tipologia_affiliazione"
                    /> -->
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Data affiliazione</label>
                    <Datepicker
                      v-model="data_inizio"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      disabled
                    />
                    <Field
                      name="data_inizio"
                      type="hidden"
                      class="form-control"
                      v-model="data_inizio"
                    />
                    <ErrorMessage name="data_inizio" style="color: red" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold">Data scadenza affiliazione</label>
                    <Datepicker
                      v-model="data_Fine"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      disabled
                    />
                    <!-- <Field
                      name="data_inizio"
                      type="hidden"
                      class="form-control"
                      v-model="affiliazione.data_inizio"
                    />
                    <ErrorMessage name="data_inizio" style="color: red" /> -->
                  </div>
                </div>
                <div class="row align-self-center">
                  <div class="col-md-12 d-flex">
                    <label class="fw-bold"
                      >Dichiaro di essere in regola con la normativa relativa ai
                      defibrillatori</label
                    >
                    <div class="mx-3 mt-1">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        name="defibrillatore"
                        :value="true"
                        v-model="defibrillatore"
                      />
                      <ErrorMessage
                        name="defibrillatore"
                        style="color: red"
                        class="ms-1"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row pb-6">
                  <p class="">Consenso al trattamento dati per REGISTRO CONI</p>
                  <div class="col-md-6 d-flex">
                    <label class="fw-bold">personali</label>
                    <div class="ms-4 mt-1">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        name="privacy_dati_pers_reg"
                        :value="true"
                        v-model="privacy_dati_pers_reg"
                      />
                      <ErrorMessage
                        name="privacy_dati_pers_reg"
                        style="color: red"
                        class="ms-1"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 d-flex">
                    <label class="fw-bold">per fini commerciali</label>
                    <div class="ms-4 mt-1">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        name="privacy_info_commerc_reg"
                        :value="true"
                        v-model="privacy_info_commerc_reg"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-4">
                  <p class="">Consenso al trattamento dati per TESSERAMENTO</p>
                  <div class="col-md-6 d-flex">
                    <label class="fw-bold">personali</label>
                    <div class="ms-4 mt-1">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        name="privacy_dati_pers_reg2"
                        :value="true"
                        v-model="privacy_dati_pers_reg2"
                      />
                      <ErrorMessage
                        name="privacy_dati_pers_reg2"
                        style="color: red"
                        class="ms-1"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 d-flex">
                    <label class="fw-bold">per fini commerciali</label>
                    <div class="ms-4 mt-1">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        name="privacy_info_commerc_reg2"
                        :value="true"
                        v-model="privacy_info_commerc_reg2"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <p class="">Discipline dell'affiliazione</p>
                  <div class="col-md-3 d-flex">
                    <label class="fw-bold">Tennis</label>
                    <div class="ms-4 mt-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="Tennis"
                        id="flexCheckDefault"
                        :checked="discipline.includes('4332') ? true : false"
                        @click="setStringDiscipline($event, '4332')"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 d-flex">
                    <label class="fw-bold">Padel</label>
                    <div class="ms-4 mt-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        :checked="discipline.includes('172') ? true : false"
                        @click="setStringDiscipline($event, '172')"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 d-flex">
                    <label class="fw-bold">Beach Tennis</label>
                    <div class="ms-4 mt-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        :checked="discipline.includes('171') ? true : false"
                        @click="setStringDiscipline($event, '171')"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 d-flex">
                    <label class="fw-bold">Wheelchair Tennis</label>
                    <div class="ms-4 mt-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        @click="setStringDiscipline($event, '173')"
                      />
                    </div>
                  </div>
                </div>
                <Field name="discipline" type="hidden" v-model="discipline" />
                <ErrorMessage name="discipline" style="color: red" />
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="active == 'atto'">
            <div class="card-body p-6">
              <AttoSocieta :societa="societa" :fromModal="true" />
            </div>
          </div>
          <div class="modal-body" v-if="active == 'consiglio'">
            <div class="card-body p-6">
              <ConsiglioSocieta :societa="societa" :fromAffiliazione="true" />
            </div>
          </div>
          <div class="modal-body" v-if="active == 'documenti'">
            <div class="card-body p-6">
              <DocumentiAffiliazione
                :societa="
                  !affiliazioneSecondaria ? societa.id : societaSecondaria
                "
                :affiliazioneSecondaria="affiliazioneSecondaria"
                :objControlloDownloadDocumentoAffiliazione="
                  objControlloDownloadDocumentoAffiliazione
                "
              />
            </div>
          </div>
          <div class="modal-body" v-if="active == 'impianti'">
            <div class="card-body p-6">
              <ImpiantiAffiliazione
                :societa="
                  !affiliazioneSecondaria ? societa.id : societaSecondaria
                "
                :societa_cod_affiliazione="
                  !affiliazioneSecondaria
                    ? societa.cod_affiliazione
                    : codAffSecondaria
                "
              />
            </div>
          </div>
          <div class="modal-body" v-if="active == 'pickleball'">
            <div class="card-body p-6">
              <PickleballAffiliazione
                :societa="
                  !affiliazioneSecondaria ? societa.id : societaSecondaria
                "
              />
            </div>
          </div>
          <div class="modal-body" v-if="active == 'tutela-minori'">
            <div class="card-body p-6">
              <TutelaMinoriAffiliazione
                :societa="
                  !affiliazioneSecondaria ? societa.id : societaSecondaria
                "
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-show="affiliazioneSecondaria && affiliazioneSecondariaCheckOk"
              @click="
                affiliazioneSecondariaCheckOk = false;
                active = 'affiliazione';
              "
              type="submit"
              class="me-3 badge bg-light-danger rounded text-danger fs-5"
            >
              Indietro
            </button>
            <span
              v-show="!affiliazioneSecondaria"
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-5"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddAff"
            >
              Chiudi
            </span>
            <button
              v-show="active == 'affiliazione' && !messaggioCheckAffiliazione"
              type="submit"
              class="badge bg-secondary rounded blueFit fs-5"
              :disabled="disableButton"
            >
              Invia richiesta
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import DocumentiAffiliazione from "./DocumentiAffiliazione.vue";
import ConsiglioSocieta from "@/views/societa/dettaglio-societa/ConsiglioSocieta.vue";
import AttoSocieta from "@/views/societa/dettaglio-societa/AttoSocieta.vue";
import ImpiantiAffiliazione from "./ImpiantiAffiliazione.vue";
import SelectComitatoSecondario from "@/components/components-fit/società/affiliazioni-secondarie/SelectComitatoSecondario.vue";
import TutelaMinoriAffiliazione from "./TutelaMinoriAffiliazione.vue";
import PickleballAffiliazione from "./PickleballAffiliazione.vue";

import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import Datepicker from "vue3-date-time-picker";
import {
  format,
  fromUTCtoISODate,
  fromUTCtoISODateDMY,
} from "@/composables/formatDate.js";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { numberImpianti } from "@/requests/addImpianto";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { affiliazioneAdd } from "@/requests/checkAffiliazione";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import {
  checkAffiliazione,
  checkAffiliazioneSecondaria,
} from "@/requests/checkAffiliazione";

export default defineComponent({
  name: "modal-add-affiliazione",
  components: {
    Loading,
    Datepicker,
    Form,
    Field,
    ErrorMessage,
    SelectComitatoSecondario,
    ConsiglioSocieta,
    DocumentiAffiliazione,
    AttoSocieta,
    ImpiantiAffiliazione,
    PickleballAffiliazione,
    TutelaMinoriAffiliazione,
  },
  emits: ["loadAffiliazioniSocieta"],
  props: {
    societa: {
      type: Object,
    },
    avviaCheck: {
      type: Boolean,
    },
    affiliazioneSecondaria: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const numeroCampi = ref(0);
    const alertCampiObb = ref(false);
    const tipologia_affiliazione = ref([]);
    const tipologia_affiliazione_string = ref(null);
    const codice_tipo_affiliazione = ref(null);
    const affiliazioneNoCampi = ref(false);

    const stagione = computed(() => store.getters.stagioneSelected);
    const data_inizio = ref(fromUTCtoISODate(new Date()));
    const data_Fine = ref(new Date(`${stagione.value}-12-31`));
    const defibrillatore = ref(false);
    const privacy_dati_pers_reg = ref(false);
    const privacy_info_commerc_reg = ref(false);
    const privacy_dati_pers_reg2 = ref(false);
    const privacy_info_commerc_reg2 = ref(false);
    const discipline = ref("");

    watch(stagione, () => {
      data_Fine.value = new Date(`${stagione.value}-12-31`);
    });

    const setStringDiscipline = (e, idDisciplina) => {
      if (e.target.checked) {
        // aggiungi id
        discipline.value = discipline.value + idDisciplina + ";";
      } else {
        //togli id disciplina
        discipline.value = discipline.value.replace(idDisciplina + ";", "");
      }
    };
    // schema validazione
    const schema = yup.object().shape({
      data_inizio: yup.string().nullable().required("Obbligatorio"),
      defibrillatore: yup
        .boolean()
        .oneOf([true], "Obbligatorio")
        .required("Obbligatorio"),
      privacy_dati_pers_reg: yup
        .boolean()
        .oneOf([true], "Obbligatorio")
        .required("Obbligatorio"),
      privacy_info_commerc_reg: yup.boolean().nullable(),
      privacy_dati_pers_reg2: yup
        .boolean()
        .oneOf([true], "Obbligatorio")
        .required("Obbligatorio"),
      privacy_info_commerc_reg2: yup.boolean().nullable(),
      discipline: yup
        .string()
        .nullable()
        .required("Selezionare almeno una disciplina"),
      tipoaff: yup.string().nullable().required("Obbligatorio"),
    });

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      if (!values.discipline.length) {
        alertFailed("Inserire una disciplina");
        return;
      }
      disableButton.value = true;
      isLoading.value = true;
      values.discipline = values.discipline.slice(0, -1);
      values = {
        ...values,
        id_societa: !props.affiliazioneSecondaria
          ? props.societa.id
          : societaSecondaria.value,
        anno: stagione.value,
        cod_affiliazione: !props.affiliazioneSecondaria
          ? props.societa.cod_affiliazione
          : codAffSecondaria.value,
      };
      affiliazioneAdd(values).then((res) => {
        if (res.status == 200) {
          alertSuccess("Affiliazione avvenuta con successo");
          document.getElementById("closeModalAddAff").click();
          emit("loadAffiliazioniSocieta");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const active = ref("affiliazione");
    const isLoadingAffiliazione = ref(false);
    const visualizzaTab = ref(false);

    const verificaImpianti = (societa) => {
      affiliazioneNoCampi.value = false;
      codice_tipo_affiliazione.value = null;
      tipologia_affiliazione_string.value = null;
      numberImpianti({
        anno: stagione.value,
        id_societa: societa,
      }).then((res) => {
        numeroCampi.value = res.data.record;
        if (numeroCampi.value > 0 && !res.data.proponiDati) {
          codice_tipo_affiliazione.value = "A001";
          tipologia_affiliazione_string.value = "Affiliazione di tipo A";
          affiliazioneNoCampi.value = false;
        } else {
          codice_tipo_affiliazione.value = "A002";
          tipologia_affiliazione_string.value = "Affiliazione di tipo B";
          affiliazioneNoCampi.value = true;
        }
      });
    };

    const messaggioCheckAffiliazione = ref("");
    const objControlloDownloadDocumentoAffiliazione = ref(null);
    const checkAff = async (societa) => {
      messaggioCheckAffiliazione.value = "";
      isLoadingAffiliazione.value = true;
      await checkAffiliazione({
        anno: stagione.value,
        id_societa: societa,
      }).then((res) => {
        isLoadingAffiliazione.value = false;
        if (res.status != 200) {
          messaggioCheckAffiliazione.value = res.data.message
            ? res.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          visualizzaTab.value = false;
        } else {
          visualizzaTab.value = true;
        }
        objControlloDownloadDocumentoAffiliazione.value =
          res.objControlloDownloadDocumentoAffiliazione;
        if (!res.result && res.errors) {
          messaggioCheckAffiliazione.value = res.errors;
          visualizzaTab.value = true;
        }
      });
      await verificaImpianti(societa);
    };

    watch(
      () => props.avviaCheck,
      () => {
        visualizzaTab.value = false;
        checkAff(props.societa.id);
        active.value = "affiliazione";
      }
    );

    //AFFILIAZIONE SECONDARIA
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const affiliazioneSecondariaCheckOk = ref(false);
    const idComitato = computed(
      () => store.getters.selectedComitatoAffSecondaria
    );
    const email = computed(() => store.getters.emailAffSecondaria);
    const comitatoSelected = ref(null);
    const societaSecondaria = ref(null);
    const codAffSecondaria = ref(null);
    const checkComitatoAffSecondaria = async () => {
      isLoading.value = true;
      affiliazioneSecondariaCheckOk.value = false;
      societaSecondaria.value = null;
      codAffSecondaria.value = null;
      comitatoSelected.value = null;
      comitati.value.forEach((element) => {
        if (element.id == parseInt(idComitato.value)) {
          comitatoSelected.value = element.label;
        }
      });
      await checkAffiliazioneSecondaria(
        props.societa ? props.societa.id : "",
        idComitato.value,
        stagione.value,
        email.value
      ).then(async (res) => {
        isLoading.value = false;
        if (res.status == 200) {
          affiliazioneSecondariaCheckOk.value = true;
          societaSecondaria.value = res.data.results[0].id_societa_secondaria;
          codAffSecondaria.value =
            res.data.results[0].cod_affiliazione_secondaria;

          await checkAff(societaSecondaria.value);
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          affiliazioneSecondariaCheckOk.value = false;
        }
      });
    };

    const resetComitato = () => {
      store.commit("setSelectedComitatoAffSecondaria", null);
      store.commit("setEmailAffSecondaria", "");
      affiliazioneSecondariaCheckOk.value = false;
    };

    return {
      schema,
      onInvalidSubmit,
      onSubmit,
      tipologia_affiliazione,
      tipologia_affiliazione_string,
      codice_tipo_affiliazione,

      data_inizio,
      data_Fine,
      defibrillatore,
      privacy_dati_pers_reg,
      privacy_info_commerc_reg,
      privacy_dati_pers_reg2,
      privacy_info_commerc_reg2,
      discipline,

      setStringDiscipline,

      format,
      fromUTCtoISODate,
      fromUTCtoISODateDMY,
      disableButton,
      isLoading,
      isLoadingAffiliazione,
      stagione,
      alertCampiObb,
      numeroCampi,

      active,
      messaggioCheckAffiliazione,
      objControlloDownloadDocumentoAffiliazione,
      visualizzaTab,
      checkAff,
      affiliazioneNoCampi,

      checkComitatoAffSecondaria,
      affiliazioneSecondariaCheckOk,
      comitatoSelected,
      resetComitato,
      societaSecondaria,
      codAffSecondaria,
    };
  },
});
</script>

<style scoped>
.container-fluid {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-light .navbar-brand {
  color: rgb(13 22 95);
  font-size: 1.1rem;
  font-weight: 500;
}

button.navbar-brand.active {
  border-radius: 0.475rem;
  transition: color 0.2s ease;
  background-color: #97b2e7;
  color: white;
  font-size: 1.1rem;
}

.navbar-brand:hover {
  text-decoration: none;
  color: white !important;
  background: #bacceed1;
  border-radius: 0.475rem;
}

.modal-content {
  width: 170%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 58%;
    margin: 2px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 46%;
    /* margin: 5.75rem auto; */
    margin-top: 5.75rem;
    margin-right: auto;
    margin-bottom: 5.75rem;
    margin-left: 7rem;
  }
}

@media (min-width: 1199.98px) {
  .modal-dialog {
    max-width: 40%;
    margin-top: 5.75rem;
    margin-right: auto;
    margin-bottom: 5.75rem;
    margin-left: 28rem;
  }
}
</style>
