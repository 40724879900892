<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center py-5 my-5" v-if="loadingView">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div v-if="!loadingView && !errore">
    <div class="d-flex pb-3 align-items-center">
      <span class=""> Numero campi da pickleball</span>
      <TextInput
        name="campi_pickleball"
        type="number"
        style="width: 7rem; margin: 0px !important"
        class="ms-4"
        :value="campi_pickleball"
        @InputEvent="
          campi_pickleball = $event;
          !campi_pickleball ? (campi_pickleball_proprieta = null) : '';
        "
      />
      <div class="text-danger ps-4" v-if="!campi_pickleball && showMsgErrore">
        Inserire un valore (anche se 0)
      </div>
    </div>
    <span class="d-flex align-items-center" v-if="campi_pickleball > 0">
      di proprietà dell’Affiliato?
      <SelectInput
        :options="trueFalseLookup"
        :value="campi_pickleball_proprieta"
        name="verifica"
        :stile="'width: 7rem'"
        :classe="'ms-4'"
        @changeSelect="campi_pickleball_proprieta = $event"
      />
      <div
        class="text-danger ps-4"
        v-if="campi_pickleball_proprieta == null && showMsgErrore"
      >
        Obbligatorio
      </div>
    </span>
  </div>
  <div class="text-danger text-center ps-4" v-if="!loadingView && errore">
    {{ errore }}
  </div>
  <div class="text-end">
    <button
      class="badge bg-secondary rounded blueFit fs-5"
      @click="addPickleball()"
    >
      Salva Modifiche
    </button>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import {
  addPickleballAffiliazione,
  viewPickleballAffiliazione,
} from "@/requests/checkAffiliazione";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "tutela-societa",
  props: ["societa"],
  components: {
    TextInput,
    SelectInput,
    Loading,
  },
  setup(props) {
    const campi_pickleball = ref(null);
    const campi_pickleball_proprieta = ref(null);
    const showMsgErrore = ref(false);
    const isLoading = ref(false);
    const loadingView = ref(false);
    const errore = ref(null);

    const getPickleball = async () => {
      loadingView.value = true;
      await viewPickleballAffiliazione(props.societa).then((res) => {
        if (res.status == 200) {
          errore.value = null;
          campi_pickleball.value = res.data.results[0].campi_pickleball;
          campi_pickleball_proprieta.value =
            res.data.results[0].campi_pickleball_proprieta;
        } else {
          errore.value = res.data.message
            ? res.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi";
        }
        loadingView.value = false;
      });
    };
    getPickleball();

    const addPickleball = async () => {
      if (
        !campi_pickleball.value ||
        (campi_pickleball.value > 0 &&
          campi_pickleball_proprieta.value == null) ||
        campi_pickleball.value < 0
      ) {
        alertFailed("Compilare i campi obbligatori");
        showMsgErrore.value = true;
      } else {
        isLoading.value = true;
        await addPickleballAffiliazione(
          props.societa,
          campi_pickleball.value,
          campi_pickleball_proprieta.value
        ).then((res) => {
          if (res.status == 200) {
            alertSuccess("Modifiche salvate correttamente");
            showMsgErrore.value = false;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
          isLoading.value = false;
        });
      }
    };

    return {
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      campi_pickleball,
      campi_pickleball_proprieta,
      addPickleball,
      showMsgErrore,
      isLoading,

      loadingView,
      errore,
    };
  },
});
</script>

<style></style>
