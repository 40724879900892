const debugPrint = false;
import services from "../axios/dbManag";
export const checkCodFisc = (string, apiRequest) => {
  return services.dbManag
    .post(apiRequest, {
      stringToCheck: string,
    })
    .then((res) => {
      if (res.data.result === "OK") {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
};
